<template>
  <PatientInfoLayout hideClose>
    <div class="tw-w-full tw-overflow-hidden">
      <div
        class="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden md:tw-px-12"
      >
        <!-- body -->
        <ChatSinglePatient></ChatSinglePatient>
      </div>
    </div>
  </PatientInfoLayout>
</template>

<script>
import PatientInfoLayout from "@/layouts/PatientInfoLayout.vue";
import ChatSinglePatient from "@/components/patients/views/ChatSinglePatient.vue";
export default {
  components: {
    PatientInfoLayout,
    ChatSinglePatient,
  },
  data() {
    return {
      patientId: "",
    };
  },
  async created() {
    this.patientId = this.$route.params.id;
    await this.$store.dispatch("patients/getPatientInfo", {
      patientId: this.patientId,
    });
  },
};
</script>

<style lang="scss" scoped>
.btn-link {
  color: $primary;
  text-decoration: underline;
}

.darkmode .btn-link {
  color: #fff;
}

.action-btn {
  border-top: 1px solid #e0e0e0;
}

.header-nav,
.action-btn {
  background-color: #fff;
}

.darkmode .header-nav,
.darkmode .action-btn {
  background-color: #000; // #121212

  @include media-breakpoint-up(md) {
    background-color: #121212; // #121212
  }
}
</style>
