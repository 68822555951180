var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-flex tw-flex-col tw-h-[calc(100vh-(260px))] sm:tw-h-[calc(100vh-(200px))] md:tw-h-[calc(100vh-(125px))] xl:tw-h-screen",
      class: _vm.mobileClass,
    },
    [
      _c(
        "b-toast",
        {
          attrs: {
            solid: "",
            toaster: "b-toaster-top-center",
            id: "cannedReplyToast",
            variant: "success",
            title: "Reply added!",
            "no-hover-pause": "",
          },
        },
        [_vm._v("Your reply has been succesfully added!")]
      ),
      _c(
        "div",
        {
          staticClass:
            "header-nav tw-top-0 tw-px-4 tw-py-5 tw-flex-none tw-bg-white md:tw-pl-0 md:tw-pr-12",
        },
        [
          _c(
            "div",
            { staticClass: "tw-flex tw-justify-between tw-items-center" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex-initial tw-flex tw-items-center tw-text-warning",
                },
                [
                  _c("span", {
                    staticClass: "back-arrow tw-mr-3 tw-cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.$router.back()
                      },
                    },
                  }),
                  _vm.isAdminView && _vm.patientInfo.firstname
                    ? [
                        _c("span", { staticClass: "tw-uppercase" }, [
                          _vm._v("Admin "),
                        ]),
                      ]
                    : [_vm._v(" Messages ")],
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "tw-pr-6 tw-hidden md:tw-block" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "tw-mr-4 text-primary tw-underline",
                      attrs: { variant: "link", pill: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.show("intakehistory")
                        },
                      },
                    },
                    [_vm._v(" view intake history ")]
                  ),
                  !_vm.isAdminView
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-primary tw-underline tw-mr-4",
                          attrs: { pill: "", variant: "link" },
                          on: {
                            click: function ($event) {
                              _vm.isAdminView = true
                            },
                          },
                        },
                        [_vm._v(" Admin ")]
                      )
                    : _vm._e(),
                  _vm.isAdminView
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-primary tw-underline tw-mr-4",
                          attrs: { pill: "", variant: "link" },
                          on: {
                            click: function ($event) {
                              _vm.isAdminView = false
                            },
                          },
                        },
                        [_vm._v(" Patient ")]
                      )
                    : _vm._e(),
                  _vm.isMarkMessagesEnabled && !_vm.isAdminView
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-secondary",
                          attrs: { pill: "", variant: "link", size: "md" },
                          on: { click: _vm.onCancelMarkMessages },
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : _vm._e(),
                  !_vm.isAdminView
                    ? _c(
                        "b-dropdown",
                        {
                          attrs: {
                            id: "forward-dropdown",
                            right: "",
                            split: "",
                            variant: "primary",
                            "split-variant":
                              _vm.forwardRequestCounter === 1
                                ? "primary"
                                : "outline-primary",
                            text: "Forward",
                            size: "md",
                          },
                          on: { click: _vm.onForwardRequest },
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show("viewForwardMessage")
                                },
                              },
                            },
                            [_vm._v(" View Forward Requests ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tw-flex md:tw-hidden" },
                [
                  _vm.isMarkMessagesEnabled && !_vm.isAdminView
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-danger",
                          attrs: { pill: "", variant: "link", size: "sm" },
                          on: { click: _vm.onCancelMarkMessages },
                        },
                        [
                          _c("b-icon", {
                            attrs: { "font-scale": "1", icon: "x-circle-fill" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isAdminView
                    ? _c(
                        "b-dropdown",
                        {
                          attrs: {
                            id: "forward-dropdown",
                            right: "",
                            split: "",
                            variant: "primary",
                            "split-variant":
                              _vm.forwardRequestCounter === 1
                                ? "primary"
                                : "outline-primary",
                            text: "Forward",
                            size: "sm",
                          },
                          on: { click: _vm.onForwardRequest },
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show("viewForwardMessage")
                                },
                              },
                            },
                            [_vm._v(" View Forward Requests ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "tw-block xl:tw-hidden",
                      attrs: {
                        variant: "link",
                        "toggle-class": "text-decoration-none",
                        "menu-class": "custom-dropdown-menu",
                        "no-caret": "",
                        right: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("b-icon", {
                                attrs: { icon: "three-dots-vertical" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      !_vm.isAdminView
                        ? _c(
                            "b-dropdown-item",
                            {
                              staticClass: "tw-uppercase",
                              attrs: { "link-class": "tw-text-primary" },
                              on: {
                                click: function ($event) {
                                  _vm.isAdminView = true
                                },
                              },
                            },
                            [_vm._v(" View Admin Messages ")]
                          )
                        : _c(
                            "b-dropdown-item",
                            {
                              staticClass: "tw-uppercase",
                              attrs: { "link-class": "tw-text-primary" },
                              on: {
                                click: function ($event) {
                                  _vm.isAdminView = false
                                },
                              },
                            },
                            [_vm._v(" View Patient Messages ")]
                          ),
                      _c(
                        "b-dropdown-item",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.intakehistory",
                              modifiers: { intakehistory: true },
                            },
                          ],
                          staticClass: "tw-uppercase",
                          attrs: { "link-class": "tw-text-primary" },
                        },
                        [_vm._v(" Intake History ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.loading
        ? [
            _c(
              "div",
              {
                staticClass:
                  "tw-flex tw-items-center tw-justify-center tw-flex-grow tw-h-screen loader-animation",
              },
              [_vm._v(" Loading conversation... ")]
            ),
          ]
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            {
              ref: "chatbox",
              staticClass:
                "tw-flex tw-flex-col tw-flex-grow card-chat-body tw-h-screen tw-overflow-y-auto",
              attrs: { "infinite-wrapper": "" },
            },
            [
              _vm.messagesLoaded && _vm.nextPage
                ? _c(
                    "infinite-loading",
                    {
                      attrs: {
                        direction: "top",
                        "force-use-infinite-wrapper": "true",
                      },
                      on: { infinite: _vm.messageScroll },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "no-more" }, slot: "no-more" },
                        [_vm._v("No more message")]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "tw-flex tw-flex-grow tw-flex-col-reverse" },
                [
                  _vm._l(_vm.messages, function (chat, i) {
                    return [
                      _c(
                        "div",
                        {
                          key: "parent-" + i,
                          staticClass:
                            "tw-flex tw-items-center tw-px-6 md:tw-px-12 tw-py-2",
                          class: {
                            "selected-message": _vm.selectedMessages.some(
                              (msg) => msg.id === chat.id
                            ),
                          },
                        },
                        [
                          _vm.isMarkMessagesEnabled
                            ? _c(
                                "div",
                                {
                                  key: "checkbox-" + i,
                                  staticClass: "tw-mr-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "forward-checkbox custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "checkbox-" + i,
                                          name: "checkbox-" + i,
                                        },
                                        domProps: {
                                          checked: _vm.isChecked(chat),
                                        },
                                        on: {
                                          change: (e) =>
                                            _vm.onForwardCheckboxChange(
                                              e,
                                              chat
                                            ),
                                        },
                                      }),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "checkbox-" + i },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          chat.type === "prescription_followup"
                            ? _c(
                                "div",
                                {
                                  key: i,
                                  staticClass:
                                    "tw-flex tw-w-full tw-mb-4 tw-py-2 tw-px-4 followup-update",
                                },
                                [
                                  chat.actionstatus === "PENDING"
                                    ? _c("p", { staticClass: "tw-mb-0" }, [
                                        _c(
                                          "span",
                                          { staticClass: "tw-block" },
                                          [
                                            _vm._v(
                                              " Follow-up questions has been sent to the patient "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "tw-block" },
                                          [
                                            _vm._v(
                                              " Time sent: " +
                                                _vm._s(
                                                  _vm.moment
                                                    .unix(chat.sentat)
                                                    .format(
                                                      "MMM DD, YYYY hh:mm A"
                                                    )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  chat.actionstatus === "ANSWERED"
                                    ? _c("p", { staticClass: "tw-mb-0" }, [
                                        _c(
                                          "span",
                                          { staticClass: "tw-block" },
                                          [
                                            _vm._v(
                                              " Follow-up questions has been answered "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "tw-block" },
                                          [
                                            _vm._v(
                                              " Time sent: " +
                                                _vm._s(
                                                  _vm.moment
                                                    .unix(chat.sentat)
                                                    .format(
                                                      "MMM DD, YYYY hh:mm A"
                                                    )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "tw-block" },
                                          [
                                            _vm._v(
                                              " Time answered: " +
                                                _vm._s(
                                                  _vm.moment
                                                    .unix(
                                                      chat.custommessage.message
                                                        .timestampanswered
                                                    )
                                                    .format(
                                                      "MMM DD, YYYY hh:mm A"
                                                    )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : chat.type === "prescription_confirmation"
                            ? _c(
                                "div",
                                {
                                  key: i,
                                  staticClass:
                                    "tw-flex tw-justify-center tw-w-full tw-mb-4 tw-py-2 tw-px-4 followup-update",
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "tw-font-hatton-medium" },
                                    [
                                      _vm._v(
                                        " Updated Treatment: " +
                                          _vm._s(
                                            chat.custommessage.message
                                              .productname
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      " The price of " +
                                        _vm._s(
                                          chat.custommessage.message.productname
                                        ) +
                                        " treatment has changed from $" +
                                        _vm._s(
                                          chat.custommessage.message.pricefrom
                                        ) +
                                        " to $" +
                                        _vm._s(
                                          chat.custommessage.message.priceto
                                        ) +
                                        ". "
                                    ),
                                  ]),
                                  chat.actionstatus === "PENDING"
                                    ? _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          " Awaiting confirmation from patient "
                                        ),
                                      ])
                                    : _vm._e(),
                                  chat.actionstatus === "ACCEPTED"
                                    ? _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          " Prescription has been accepted by patient "
                                        ),
                                      ])
                                    : _vm._e(),
                                  chat.actionstatus === "DECLINED"
                                    ? _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          " Prescription has been declined by patient "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : chat.type === "reassignment_transfer"
                            ? _c(
                                "div",
                                { key: i },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      staticClass: "followup-update mt-1 mb-1",
                                    },
                                    [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _c(
                                          "span",
                                          { staticClass: "d-block mb-1" },
                                          [
                                            _vm._v(
                                              " Patient has been reassigned to "
                                            ),
                                            chat.custommessage.message
                                              .doctoridto === _vm.userid
                                              ? _c("span", [
                                                  _vm._v(
                                                    " you from Dr. " +
                                                      _vm._s(
                                                        chat.custommessage
                                                          .message.doctorfrom
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " Dr. " +
                                                      _vm._s(
                                                        chat.custommessage
                                                          .message.doctorto
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                            chat.custommessage.message
                                              .reassignmentstatus !== "RETURNED"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      chat.custommessage.message
                                                        .assignmenttype ===
                                                        "TEMPORARY"
                                                        ? "temporarily"
                                                        : "permanently"
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        chat.custommessage.message
                                          .assignmenttype === "TEMPORARY" &&
                                        chat.custommessage.message
                                          .reassignmentstatus !== "RETURNED"
                                          ? _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "d-block text-left time-stamp-followup",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.moment
                                                        .unix(
                                                          chat.custommessage
                                                            .message
                                                            .startdatestamp
                                                        )
                                                        .format(
                                                          "MMM DD, YYYY hh:mm A"
                                                        )
                                                    ) +
                                                    " to " +
                                                    _vm._s(
                                                      _vm.moment
                                                        .unix(
                                                          chat.custommessage
                                                            .message
                                                            .enddatestamp
                                                        )
                                                        .format(
                                                          "MMM DD, YYYY hh:mm A"
                                                        )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : chat.type === "forward_message"
                            ? _c(
                                "div",
                                {
                                  key: `forward-${i}`,
                                  staticClass:
                                    "bubble-chat tw-flex tw-items-end",
                                  class: [
                                    chat.userid != _vm.userid
                                      ? "patient"
                                      : "doctor",
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex-shrink-0 img-holder tw-h-[30px] tw-w-[30px] tw-rounded-full tw-overflow-hidden",
                                      staticStyle: { "font-size": "0" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "tw-object-cover",
                                        attrs: {
                                          src: _vm.getMemberProfileUrl(
                                            chat.userid
                                          ),
                                          width: "30",
                                          height: "30",
                                          alt: "name of the patient or doctor",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-relative tw-ml-[10px] tw-p-4 tw-max-w-lg",
                                      style:
                                        chat.custommessage.forwardmessage
                                          .messagestatus === "COMPLETED"
                                          ? "background-color: #F5FEEF"
                                          : "background-color: rgba(26, 106, 114, 0.05)",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tw-text-right tw-mb-2",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "text-secondary tw-cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.$refs.viewForwardMessages.onView(
                                                    chat.custommessage
                                                      .forwardmessage.id,
                                                    chat.custommessage
                                                      .forwardmessage
                                                      .conversationid
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" View ")]
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        chat.custommessage.forwardmessage.messagelist
                                          .slice()
                                          .reverse(),
                                        function (forwardedMessage) {
                                          return _c(
                                            "div",
                                            {
                                              key: forwardedMessage.id,
                                              staticClass: "tw-mb-2",
                                            },
                                            [
                                              _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "text-muted tw-text-[11px] tw-block",
                                                },
                                                [
                                                  _vm._v(
                                                    " Sent by " +
                                                      _vm._s(
                                                        `${forwardedMessage.firstname} ${forwardedMessage.lastname}`
                                                      ) +
                                                      " at " +
                                                      _vm._s(
                                                        _vm.moment
                                                          .unix(
                                                            forwardedMessage.sentat
                                                          )
                                                          .format(
                                                            "MMM DD, YYYY hh:mm A"
                                                          )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tw-px-4 tw-py-2 tw-bg-white tw-inline-block",
                                                  style:
                                                    forwardedMessage.usertype ===
                                                    "PATIENT"
                                                      ? "background-color: #FFECCA"
                                                      : "",
                                                },
                                                [
                                                  forwardedMessage.attachment &&
                                                  forwardedMessage.attachment
                                                    .length > 0
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          forwardedMessage.attachment,
                                                          function (
                                                            chatAttachment,
                                                            i
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: i,
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "transparent",
                                                                },
                                                              },
                                                              [
                                                                chatAttachment.type !=
                                                                "application/pdf"
                                                                  ? _c(
                                                                      "b-row",
                                                                      {
                                                                        attrs: {
                                                                          "no-gutters":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-col",
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    href: chatAttachment.url,
                                                                                    variant:
                                                                                      "link",
                                                                                    download:
                                                                                      "",
                                                                                    target:
                                                                                      "_blank",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b-img",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        thumbnail:
                                                                                          "",
                                                                                        fluid:
                                                                                          "",
                                                                                        src: chatAttachment.url,
                                                                                        width:
                                                                                          "250px",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "b-row",
                                                                      {
                                                                        attrs: {
                                                                          "no-gutters":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                href: chatAttachment.url,
                                                                                variant:
                                                                                  "link",
                                                                                download:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: "arrow-down-circle-fill",
                                                                                    variant:
                                                                                      "primary",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  chatAttachment.filename
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        forwardedMessage.message
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      chat.custommessage.forwardmessage
                                        .messagestatus === "COMPLETED"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tw-text-center tw-mt-2 tw-text-sm tw-text-green-900",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    chat.custommessage
                                                      .forwardmessage
                                                      .messagestatus
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  key: i,
                                  staticClass:
                                    "bubble-chat tw-flex tw-items-end",
                                  class: [
                                    chat.userid != _vm.userid
                                      ? "patient"
                                      : "doctor",
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-flex-shrink-0 img-holder tw-h-[30px] tw-w-[30px] tw-rounded-full tw-overflow-hidden",
                                      staticStyle: { "font-size": "0" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "tw-object-cover",
                                        attrs: {
                                          src: _vm.getMemberProfileUrl(
                                            chat.userid
                                          ),
                                          width: "30",
                                          height: "30",
                                          alt: "name of the patient or doctor",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "chat-container tw-flex-0" },
                                    [
                                      chat.attachment &&
                                      chat.attachment.length > 0
                                        ? [
                                            _vm._l(
                                              chat.attachment,
                                              function (chatAttachment, i) {
                                                return [
                                                  chatAttachment.type.includes(
                                                    "image"
                                                  ) &&
                                                  !["tiff", "heic", "svg"].some(
                                                    (type) =>
                                                      chatAttachment.type.includes(
                                                        type
                                                      )
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          key: i,
                                                          staticClass:
                                                            "shared-image tw-flex tw-flex-wrap",
                                                          class: chat.message
                                                            ? "tw-mb-3"
                                                            : "",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: chatAttachment.url,
                                                                variant: "link",
                                                                download: "",
                                                                target:
                                                                  "_blank",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "tw-object-cover tw-w-full tw-h-full",
                                                                attrs: {
                                                                  src: chatAttachment.url,
                                                                  width: "250",
                                                                  height: "250",
                                                                  alt: chatAttachment.filename,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : [
                                                        _c(
                                                          "div",
                                                          {
                                                            key: i,
                                                            staticClass: "p-3",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: chatAttachment.url,
                                                                  variant:
                                                                    "link",
                                                                  download: "",
                                                                },
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: "arrow-down-circle-fill",
                                                                    variant:
                                                                      "primary",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      chatAttachment.filename
                                                                    )
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                ]
                                              }
                                            ),
                                          ]
                                        : _vm._e(),
                                      chat.message
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "messages p-3",
                                              class:
                                                _vm.isAdminView &&
                                                chat.userid != _vm.userid
                                                  ? "admin"
                                                  : "",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(chat.message) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "timestamp tw-flex-shrink-0 tw-flex tw-flex-col",
                                    },
                                    [
                                      _vm.isAdminView &&
                                      chat.userid != _vm.userid
                                        ? _c(
                                            "small",
                                            {
                                              staticClass:
                                                "tw-text-[10px] tw-text-muted",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getAdminName(
                                                      chat.userid
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "tw-text-[10px] tw-text-muted",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.moment
                                                  .unix(chat.sentat)
                                                  .format("MMM DD, YYYY ")
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.moment
                                                  .unix(chat.sentat)
                                                  .format("hh:mm A")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("ChatRoutedTooltip", {
                                        staticClass: "tw-text-xs",
                                        attrs: { message: chat },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c("div", { ref: "bottomOfPage", attrs: { id: "bottomOfPage" } }),
            ],
            1
          )
        : _vm._e(),
      _c("div", [
        _vm.needsPatientReply && !_vm.isAdminView
          ? _c("div", { staticClass: "tw-text-center text-secondary" }, [
              _vm._v(" Needs patient reply "),
            ])
          : _vm._e(),
        _vm.isAdminView && _vm.patientInfo.firstname
          ? _c(
              "div",
              { staticClass: "tw-text-center text-primary tw-mx-3 tw-py-2" },
              [
                _vm._v(
                  " You are now messaging the admin assigned to " +
                    _vm._s(
                      `${_vm.patientInfo.firstname} ${_vm.patientInfo.lastname}`
                    ) +
                    ". "
                ),
              ]
            )
          : _vm._e(),
        !_vm.currentDoctor.id
          ? _c(
              "div",
              {
                staticClass:
                  "tw-p-2 md:tw-px-4 tw-flex-none md:tw-sticky md:tw-bottom-0 action-btn tw-relative tw-w-full",
              },
              [
                _vm.preUpload
                  ? _c(
                      "div",
                      { staticClass: "pre-upload" },
                      _vm._l(_vm.attachments, function (attachment, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "img-holder" },
                          [
                            _c("b-icon", {
                              staticClass: "close-btn",
                              attrs: { icon: "x-circle-fill" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile(index)
                                },
                              },
                            }),
                            !attachment.type.includes("image") ||
                            attachment.type.includes("heic")
                              ? _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "32",
                                      height: "32",
                                      viewBox: "0 0 24 24",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        fill: "currentColor",
                                        d: "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z",
                                      },
                                    }),
                                  ]
                                )
                              : _c("img", {
                                  attrs: {
                                    src: attachment.url,
                                    alt: attachment.name,
                                  },
                                }),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-grid tw-grid-cols-2 md:tw-flex md:tw-items-center tw-w-full",
                    class: { "!tw-flex": _vm.$_orientation == "landscape" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tw-col-span-2 tw-flex tw-flex-1" },
                      [
                        _c(
                          "div",
                          { staticClass: "tw-flex-1" },
                          [
                            _c("b-form-textarea", {
                              ref: "messageArea",
                              staticClass: "tw-px-0 tw-border-b-0",
                              attrs: {
                                "max-rows": "5",
                                "no-resize": "",
                                placeholder: "Write a message...",
                              },
                              model: {
                                value: _vm.newMessage,
                                callback: function ($$v) {
                                  _vm.newMessage = $$v
                                },
                                expression: "newMessage",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-flex-initial tw-flex tw-items-center",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "link" },
                                    on: { click: _vm.openUpload },
                                  },
                                  [
                                    _c("icon-paperclip", {
                                      staticClass:
                                        "tw-w-5 tw-h-5 tw-text-gray-800",
                                    }),
                                  ],
                                  1
                                ),
                                _c("input", {
                                  ref: "fileInput",
                                  staticStyle: { display: "none" },
                                  attrs: { type: "file", multiple: "" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleFilesUpload()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "link" },
                                on: {
                                  click: function ($event) {
                                    _vm.showCannedReplyModal = true
                                  },
                                },
                              },
                              [
                                _c("icon-canned-reply", {
                                  staticClass: "tw-w-5 tw-h-5 tw-text-gray-800",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-col-span-2 md:tw-flex-initial tw-flex tw-items-center tw-flex-row-reverse md:tw-flex-col tw-p-2",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "tw-w-1/2 tw-h-[63px] tw-ml-3 md:tw-w-full md:tw-h-auto md:tw-ml-0 md:tw-mb-3",
                            class: _vm.isAdminView
                              ? "!tw-w-full !tw-h-auto !tw-ml-0"
                              : "",
                            attrs: {
                              variant: "primary",
                              pill: "",
                              block: "",
                              disabled: _vm.isMessageBodyEmpty,
                            },
                            on: { click: () => _vm.sendMessage() },
                          },
                          [
                            _c("span", { staticClass: "tw-text-xs" }, [
                              _vm._v(" Send Message "),
                            ]),
                          ]
                        ),
                        !_vm.isAdminView
                          ? _c(
                              "b-button",
                              {
                                staticClass: "tw-w-1/2 !tw-mt-0 md:tw-w-full",
                                attrs: {
                                  variant: "danger",
                                  pill: "",
                                  block: "",
                                  disabled: _vm.isMessageBodyEmpty,
                                },
                                on: { click: _vm.sendMessageNeedsReply },
                              },
                              [
                                _c("span", { staticClass: "tw-text-xs" }, [
                                  _vm._v(" Send Message Needs Reply "),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("SideModal", {
        key: _vm.cannedReplyModalKey,
        attrs: {
          show: _vm.showCannedReplyModal,
          "header-class": "canned-reply-header",
          "body-class": "canned-reply-body",
          title: "Canned Replies",
        },
        on: {
          exit: function ($event) {
            _vm.showCannedReplyModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("CannedReplyModal", {
                  attrs: {
                    "patient-name": _vm.patientInfo.firstname,
                    "is-visible": _vm.showCannedReplyModal,
                  },
                  on: {
                    "close-canned": function ($event) {
                      _vm.showCannedReplyModal = false
                    },
                    "add-attachment": _vm.addAttachment,
                    "add-message": _vm.addMessage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal-forward-messages", {
        attrs: {
          "selected-messages": _vm.selectedMessages,
          conversationmemberlist: _vm.conversationmemberlist,
          conversationid: _vm.conversationid,
        },
        on: { success: _vm.onForwardMessageSuccess },
      }),
      _c("modal-view-forward-messages", {
        ref: "viewForwardMessages",
        attrs: {
          conversationmemberlist: _vm.conversationmemberlist,
          conversationid: _vm.conversationid,
        },
      }),
      _c(
        "portal",
        { attrs: { to: "needsreply" } },
        [
          !_vm.currentDoctor.id
            ? [
                _vm.needsPatientReply
                  ? _c(
                      "b-button",
                      {
                        staticClass: "pb-0 px-0",
                        staticStyle: { "box-shadow": "none !important" },
                        attrs: { variant: "link", block: "" },
                        on: { click: _vm.onRemoveNeedPatientReply },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-secondary d-inline-block btn-patient-reply",
                          },
                          [_vm._v(" Clear Needs Reply ")]
                        ),
                        _c("br"),
                      ]
                    )
                  : _c(
                      "b-button",
                      {
                        staticClass: "pb-0 px-0",
                        staticStyle: { "box-shadow": "none !important" },
                        attrs: { variant: "link", block: "" },
                        on: { click: _vm.onNeedPatientReply },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-secondary d-inline-block btn-patient-reply",
                          },
                          [_vm._v(" Mark Needs Reply ")]
                        ),
                        _c("br"),
                      ]
                    ),
              ]
            : _vm._e(),
          _vm.patientLastReplyDate && !_vm.currentDoctor.id
            ? _c(
                "div",
                {
                  staticClass:
                    "text-black text-center patient-reply-description",
                },
                [
                  _c("small", [
                    _vm._v(" This patient hasn’t replied for "),
                    _c("strong", [
                      _vm._v(" " + _vm._s(_vm.patientLastReplyDate) + " "),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _c("modal-intake-history", {
        attrs: { "patient-info": _vm.patientInfo },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }