var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "intakehistory",
        centered: "",
        "hide-footer": "",
        size: "xl",
        scrollable: "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c("b-container", { attrs: { fluid: "" } }),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "link" },
                  on: { click: close },
                },
                [
                  _c("b-icon", {
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: { icon: "x-circle-fill", variant: "secondary" },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex-grow" },
        [
          _vm._l(
            _vm.patientInfo.followupquestions,
            function (followups, index) {
              return _c("div", { key: index, staticClass: "mb-5" }, [
                _c("h4", { staticClass: "tw-mb-6 tw-text-xl tw-text-muted" }, [
                  _vm._v(
                    " " +
                      _vm._s(followups.type === "1YEAR" ? "1YR" : "10WK") +
                      " Follow-Up Questions | " +
                      _vm._s(
                        _vm.parseDate(
                          followups.timestampanswered,
                          "MM/dd/yyyy hh:mmaaa"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "health-question-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "health-question" },
                    _vm._l(
                      followups.followupanswer,
                      function (questionitems, indexquestions) {
                        return _c(
                          "div",
                          { key: indexquestions, staticClass: "hq-list" },
                          [
                            _c("div", { staticClass: "tw-pb-6" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "intake-label",
                                  attrs: { for: `followups${indexquestions}` },
                                },
                                [
                                  _vm._v(
                                    _vm._s(indexquestions + 1) +
                                      ". " +
                                      _vm._s(questionitems.question) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "pl-0 intake-input",
                                  style: `color:${questionitems.highlightcolor}`,
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        questionitems.answer.replace(
                                          /(\r\n|\n|\r)/gm,
                                          "\r\n"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ])
            }
          ),
          _c("h4", { staticClass: "tw-mb-6 tw-text-xl tw-text-muted" }, [
            _vm._v("Health Questions"),
          ]),
          _c("div", { staticClass: "health-question-wrapper" }, [
            _c(
              "div",
              { staticClass: "health-question" },
              _vm._l(
                _vm.patientInfo.questions,
                function (healthQuestion, index) {
                  return _c("div", { key: index, staticClass: "hq-list" }, [
                    _c("div", { staticClass: "tw-pb-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "intake-label",
                          attrs: { for: `healthQuestion${index}` },
                        },
                        [
                          _vm._v(
                            _vm._s(index + 1) +
                              ". " +
                              _vm._s(healthQuestion.question) +
                              " "
                          ),
                        ]
                      ),
                      healthQuestion.question != "Stated allergies"
                        ? _c(
                            "p",
                            {
                              staticClass: "pl-0 intake-input",
                              style: `color:${healthQuestion.highlightcolor}; border-bottom: 1px solid #D2DDEC;`,
                            },
                            [_vm._v(" " + _vm._s(healthQuestion.answer) + " ")]
                          )
                        : _c(
                            "p",
                            {
                              staticClass: "pl-0 intake-input",
                              style: `color:${healthQuestion.highlightcolor}`,
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    healthQuestion.answer.replace(
                                      /(\r\n|\n|\r)/gm,
                                      "\r\n"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ]),
                  ])
                }
              ),
              0
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }