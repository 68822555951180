<template>
  <b-modal id="intakehistory" centered hide-footer size="xl" scrollable>
    <template v-slot:modal-header="{ close }">
      <b-container fluid></b-container>
      <!-- Emulate built in modal header close button action -->
      <b-button size="sm" variant="link" @click="close">
        <b-icon
          icon="x-circle-fill"
          variant="secondary"
          style="width: 20px; height: 20px"
        ></b-icon>
      </b-button>
    </template>
    <div class="tw-flex-grow">
      <div
        v-for="(followups, index) in patientInfo.followupquestions"
        :key="index"
        class="mb-5"
      >
        <h4 class="tw-mb-6 tw-text-xl tw-text-muted">
          {{ followups.type === "1YEAR" ? "1YR" : "10WK" }} Follow-Up Questions
          |
          {{ parseDate(followups.timestampanswered, "MM/dd/yyyy hh:mmaaa") }}
        </h4>

        <div class="health-question-wrapper">
          <div class="health-question">
            <div
              class="hq-list"
              v-for="(
                questionitems, indexquestions
              ) in followups.followupanswer"
              :key="indexquestions"
            >
              <div class="tw-pb-6">
                <label class="intake-label" :for="`followups${indexquestions}`"
                  >{{ indexquestions + 1 }}. {{ questionitems.question }}
                </label>

                <p
                  class="pl-0 intake-input"
                  :style="`color:${questionitems.highlightcolor}`"
                >
                  {{ questionitems.answer.replace(/(\r\n|\n|\r)/gm, "\r\n") }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- <ul
          class="
            list-unstyled
            tw-grid tw-grid-cols-1
            md:tw-grid-cols-2
            tw-gap-10
          "
        >
          <li
            class="tw-row-span-1 tw-overflow-hidden"
            v-for="(questionitems, indexquestions) in followups.followupanswer"
            :key="indexquestions"
          >
            <label class="intake-label" :for="`followups${indexquestions}`"
              >{{ indexquestions + 1 }}. {{ questionitems.question }}
            </label>
            <b-form-textarea
              disabled
              max-rows="15"
              class="pl-0 intake-input"
              type="text"
              style="overflow: hidden"
              :value="questionitems.answer.replace(/(\r\n|\n|\r)/gm, '\r\n')"
              :style="`color:${questionitems.highlightcolor}`"
            ></b-form-textarea>
          </li>
        </ul> -->
      </div>

      <h4 class="tw-mb-6 tw-text-xl tw-text-muted">Health Questions</h4>

      <div class="health-question-wrapper">
        <div class="health-question">
          <div
            class="hq-list"
            v-for="(healthQuestion, index) in patientInfo.questions"
            :key="index"
          >
            <div class="tw-pb-6">
              <label class="intake-label" :for="`healthQuestion${index}`"
                >{{ index + 1 }}. {{ healthQuestion.question }}
              </label>
              <p
                v-if="healthQuestion.question != 'Stated allergies'"
                class="pl-0 intake-input"
                :style="`color:${healthQuestion.highlightcolor}; border-bottom: 1px solid #D2DDEC;`"
              >
                {{ healthQuestion.answer }}
              </p>

              <p
                v-else
                class="pl-0 intake-input"
                :style="`color:${healthQuestion.highlightcolor}`"
              >
                {{ healthQuestion.answer.replace(/(\r\n|\n|\r)/gm, "\r\n") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <ul
        class="list-unstyled tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-10"
      >
        <li
          class="tw-row-span-1 tw-overflow-hidden"
          v-for="(healthQuestion, index) in patientInfo.questions"
          :key="index"
        >
          <label class="intake-label" :for="`healthQuestion${index}`"
            >{{ index + 1 }}. {{ healthQuestion.question }}
          </label>
          <p
            v-if="healthQuestion.question != 'Stated allergies'"
            class="pl-0 intake-input"
            :style="`color:${healthQuestion.highlightcolor}; border-bottom: 1px solid #D2DDEC;`"
          >
            {{ healthQuestion.answer }}
          </p>
          <b-form-textarea
            v-else
            disabled
            max-rows="15"
            class="pl-0 intake-input"
            type="text"
            style="overflow: hidden"
            :value="healthQuestion.answer.replace(/(\r\n|\n|\r)/gm, '\r\n')"
            :style="`color:${healthQuestion.highlightcolor}`"
          ></b-form-textarea>
        </li>
      </ul> -->
    </div>
  </b-modal>
</template>

<script>
import { format, fromUnixTime } from "date-fns";
export default {
  props: {
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    parseDate(date, stringFormat) {
      if (date) {
        return format(fromUnixTime(date), stringFormat);
      }
    },
  },
};
</script>

<style scoped>
/deep/#intakehistory .modal-header {
  border: none;
}

.health-question-wrapper {
  column-count: 2;
  column-gap: 2em;
}

.health-question {
  display: grid;
  grid-template-rows: 1fr auto;
}

.hq-list > div {
  break-inside: avoid;
}

.intake-label {
  /* color: #333333; */
  font-size: 12px;
}

.intake-input {
  border-color: #bdbdbd;
  /* color: #000; */
}
</style>
