var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("PatientInfoLayout", { attrs: { hideClose: "" } }, [
    _c("div", { staticClass: "tw-w-full tw-overflow-hidden" }, [
      _c(
        "div",
        {
          staticClass:
            "tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden md:tw-px-12",
        },
        [_c("ChatSinglePatient")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }